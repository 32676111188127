<template>
  <div class="p-grid p-justify-center">
    <div class="p-col-12 p-md-8">
      <div class="card p-fluid">
        <h5>Создание чеклиста</h5>
        <div class="p-field">
          <label>Выберите тип</label>
          <Dropdown
            v-model="selectedType.val"
            :options="checklistTypes"
            optionLabel="name"
            placeholder="Выберите тип"
            :class="!selectedType.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div class="p-field">
          <label>Введите название</label>
          <InputText
            v-model="checklistName.val"
            :class="!checklistName.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div class="p-field">
          <label>Введите стоимость нормо-часа (в рублях)</label>
          <InputNumber
            v-model="checklistNormalHourPrice.val"
            :class="!checklistNormalHourPrice.isValid ? 'p-invalid' : ''"
          />
        </div>

        <div class="p-field">
          <Button label="Сохранить" @click="submitChecklist"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "CreateChecklist",
  data() {
    return {
      isLoading: false,
      selectedType: {
        val: null,
        isValid: true,
      },
      checklistName: {
        val: null,
        isValid: true,
      },
      checklistNormalHourPrice: {
        val: 0,
        isValid: true,
      },
    };
  },
  created() {
    this.getItemsTypes();
  },
  computed: {
    checklistTypes() {
      return this.$store.getters["data/workTypes"];
    },
  },
  methods: {
    async getItemsTypes() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates/items/types`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        console.log(await response.json());
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    validateForm() {
      this.formIsValid = true;
      this.checklistName.isValid = true;
      this.selectedType.isValid = true;
      this.checklistNormalHourPrice.isValid = true;

      if (!this.selectedType.val) {
        this.formIsValid = false;
        this.selectedType.isValid = false;
      }

      if (!this.checklistName.val) {
        this.formIsValid = false;
        this.checklistName.isValid = false;
      }

      if (this.checklistNormalHourPrice.val < 0) {
        this.formIsValid = false;
        this.checklistNormalHourPrice.isValid = false;
      }
    },
    async submitChecklist() {
      this.validateForm();
      if (!this.formIsValid) return;

      this.isLoading = true;

      try {
        const payload = {
          name: this.checklistName.val,
          type: this.selectedType?.val?.value,
          normalHourPrice: this.checklistNormalHourPrice.val * 100,
        };
        await fetch(`${environment.apiUrl}/checklist_templates`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        });
        await this.$router.push("/checklist_maker");
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
